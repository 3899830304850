@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-VariableFont_slnt,wght.ttf") format("opentype");
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../fonts/Roboto_Condensed/RobotoCondensed-VariableFont_wght.ttf")
    format("opentype");
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/Montserrat-VariableFont_wght.ttf")
    format("opentype");
}
