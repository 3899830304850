:root {
  --margin: 20px;

  /* fonts */
  --fs-tiny: 0.75rem;
  --fs-small: 0.875rem;
  --fs-smallish: 1rem;
  --fs-medium: 1.125rem;
  --fs-biggish: 1.5rem;
  --fs-big: 1.875rem;
  --fs-header: 1.875rem;

  --font-text: "Inter";
  --font-alt: "Montserrat";
  --font-logo: "Roboto Condensed";
}

@media only screen and (max-width: 480px) {
  :root {
    --fs-big: 1.6rem;
    --fs-header: 1.6rem;

    --margin: 10px;
  }
}

/* Make app full-height */
html,
body,
#root {
  height: 100%;
}

body {
  font-family: var(--font-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fs-medium);
  min-width: 328px;
}

#webpack-dev-server-client-overlay {
  display: none;
}

a {
  color: var(--highlight-link);
}
